import { ParsedUrlQuery } from 'querystring'
import { useMemo, useState } from 'react'
import { QueryKeysEnum } from 'shared/route-query/enum/query-keys-enum'
import { useQuery } from 'shared/route-query/hooks/use-query'
import { getFilterQuery } from 'shared/route-query/utils/get-filter-query'
import { setFilterFromQuery } from 'shared/route-query/utils/set-filter-from-query'
import { deepCompare } from 'shared/utils/deepCompare'

interface UseFilterOptionsInterface<T> {
  isStatic?: boolean
  getDefaultDateRange?: (timeZone?: string) => [string, string]
  defaultDynamicFilter?: T
}

export const useFilter = <T>(defaultStaticFilter: T, options?: UseFilterOptionsInterface<T>) => {
  const defaultFilter = useMemo(
    () => options?.defaultDynamicFilter ?? defaultStaticFilter,
    [defaultStaticFilter, options?.defaultDynamicFilter],
  )

  const [filter, setFilter] = useState<T>(defaultFilter ?? defaultStaticFilter)

  const memoizedQueryData = useMemo(() => {
    if (!defaultFilter) return

    if (deepCompare(filter, defaultStaticFilter)) {
      return
    }

    const query: ParsedUrlQuery = {}

    try {
      const filterQuery = getFilterQuery({ filter, defaultFilter })
      if (Object.keys(filterQuery).length > 0) {
        query['filter'] = JSON.stringify(filterQuery)
      }
    } catch {}

    return query
  }, [defaultFilter, defaultStaticFilter, filter])

  useQuery({
    skip: options?.isStatic,
    data: memoizedQueryData,
    onChange: query => {
      const filterQuery = setFilterFromQuery(query)
      if (filterQuery) {
        try {
          const newFilter = { ...defaultFilter, ...JSON.parse(filterQuery) }
          setFilter(newFilter)
        } catch {}
      } else {
        setFilter(defaultFilter ?? defaultStaticFilter)
      }
    },
    keys: [QueryKeysEnum.Filter],
  })

  return { filter, setFilter }
}
