import { ParsedUrlQuery } from 'querystring'

export const getFilterQuery = <T extends Record<string, any>>({
  filter,
  defaultFilter,
}: {
  filter: T
  defaultFilter: T
}) => {
  const newFilterQuery = {} as ParsedUrlQuery

  const filterKeys = Object.keys(filter) as Array<keyof T>

  filterKeys.forEach(el => {
    if (filter[el] !== defaultFilter[el]) {
      newFilterQuery[el as string] = filter[el]
    }
  })

  //for date-range filter
  const dateSince = 'dateSince'
  const dateSinceFilter = filterKeys.find(el => el === dateSince)
  const dateTill = 'dateTill'
  const dateTillFilter = filterKeys.find(el => el === dateTill)
  if (
    (dateSinceFilter && filter[dateSince] !== defaultFilter[dateSince]) ||
    (dateTillFilter && filter[dateTill] !== defaultFilter[dateTill])
  ) {
    newFilterQuery[dateSince] = filter[dateSince]
    newFilterQuery[dateTill] = filter[dateTill]
  }

  return newFilterQuery
}
