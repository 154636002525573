import { CurrencyEnum } from 'shared/enums/currency-enum'
import { getLanguageCodeByLocale } from 'shared/enums/language-code-enum'
import { LocaleEnum } from 'shared/enums/locale-enum'

export const currencyFormat = (
  amount: number,
  currency: CurrencyEnum,
  locale?: LocaleEnum,
  noDecimals?: boolean,
) =>
  Intl.NumberFormat(getLanguageCodeByLocale(locale ?? LocaleEnum.ENGLISH), {
    style: 'currency',
    currency: currency,
    currencyDisplay: 'symbol',
    maximumFractionDigits: noDecimals ? 0 : undefined,
    minimumFractionDigits: noDecimals ? 0 : 2, // we need 2 fractional digits for tests
  }).format(amount)
